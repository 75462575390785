
export default {
  name: "FAQS",
  data() {
    return {
      faqs: [
        {
          title: "هل أحتاج إلى خبرة تقنية لإعداد متجري ؟",
          content:
            "لا تحتاج لأي خبرة تقنية ، في متجرة سهلنا عليك كل شيء وكمان وفرنا لك فريق دعم فني متميز يساعدك في إعداد وتجهيز متجرك ومساعدتك دائما",
        },
        {
          title: "ماهي الخدمات المشمولة في الباقات؟",
          content:
            "تشمل الباقات جميع الميزات والخدمات اللازمة لإطلاق متجرك الإلكتروني بداية من مرحلة الإعداد وحتى الربط مع مزودي الخدمات بأسعار حصرية وتفعيل الدفع الالكتروني بدون أي رسوم تأسيسية أو شهرية وحتى التسويق عن طريق نظام التسويق",
        },
        {
          title: "هل الاشتراك يشمل تحديثات لمتجري؟",
          content:
            " نعم الاشتراك يشمل تحديثات مستمرة بإضافة مميزات جديدة والربط مع مزودي خدمات",
        },
        {
          title: "هل أستطيع إلغاء الخدمة والحصول على بيانات متجري ؟",
          content: "نعم تستطيع في أي وقت أن تفعل ذلك دون أي قيود أو شروط",
        },
        {
          title: "كيف انشئ متجر الكتروني بسرعة؟",
          content:
            'يُمكنك إنشاء متجر إلكتروني على الإنترنت بسهولة مع منصة متجرة أفضل منصة تصميم متاجر إلكترونية في السعودية دون الحاجة لشركة برمجة. <br /> حيث تُمكنك منصة متجرة من إنشاء متجر إلكتروني متكامل؛ لتوفيرها تصميم للمستخدم العادي - التاجر - الذي لا يوجد له معرفة بالبرمجة وتصميم المتاجر.  <br /> كما توفر لك منصة متجر عديد من الميزات والتسهيلات من أجل بدء عملك في التجارة الإلكترونية بسهولة والربح من الإنترنت سريعاً في وقت قصير وبسعر تنافسي.  <br /> لذلك إذا كنت تريد إنشاء متجر إلكتروني متكامل بسهولة عليك الدخول إلى منصة متجرة واختيار الباقة المناسبة لك ومن ثم ستقودك الخطوات بالترتيب لإنشاء متجر إلكتروني في خلال ساعات معدودة.  <br /> ولا تقلق عند إنشاء متجرك بمنصة متجرة، لأنك ستحصل على عدة مزايا تستفاد منها في أي وقت، حيث تتميز المنصة بتوفير دعم متكامل باللغة العربية، إلى الجانب الكثير من الحلول والميزات مثل، الدمج مع أشهر وأكبر شركات الشحن وبوابات الدفع في العالم.  <br /> بالإضافة لإمكانية إنشاء المتجر بكلتا اللغتين "العربية والإنجليزية"، مع وجود دعم فني متكامل على مدار الـ 24 ساعة، وتوفير إمكانية إنشاء تطبيق هاتف جوال لمتجرك أيضًا.',
        },
      ],
    };
  },
};
